
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    console.log('self in data function:');
    console.log(self);
    var dao = {};
    if (self.Id) {
      dao['Id'] = self.Id;
      dao['Name'] = self.Name;
      dao['MaxSpots'] = self.MaxSpots;
    } else {
      dao['Id'] = self.$route.params.Id;
      dao['Name'] = self.$route.params.Name;
      dao['MaxSpots'] = self.$route.params.MaxSpots;
    }
    return {
      sequence: [...Array(Number(dao.MaxSpots) + 1).keys()],
      id: dao.Id,
      name: dao.Name
    };
  },
  methods: {
    UpdateEVPlace: function (n) {
      var self = this;
      console.log('UpdateEVPlace()');
      self.$app.preloader.show();
      var dt = new Date();
      self.$app.data.db.collection('ev_logbook').insertOne({
        owner_id: self.$app.data.stitchUserId,
        place_id: new stitch.BSON.ObjectId(self.id),
        free: n,
        ts: dt
      }).then(res => {
        self.$app.data.db.collection('ev_places').updateOne({ _id: new stitch.BSON.ObjectId(self.id) }, { '$set': { 'last_updated_on': dt } }).then(res => {
          self.$app.preloader.hide();
          self.$f7router.back();
        });
      }).catch(e => {
        self.$app.preloader.hide();
        self.$app.dialog.alert('Failed to update EV Place: ' + e);
      });
    }
  },
  id: 'fa981c97eb',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n    <div class="navbar">\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back"></i>\n            <span class="ios-only">Back</span>\n          </a>\n        </div>\n\t<div class="title">How many <b>free</b> spots you see?</div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="block block-strong">\n        <p>EV place: <b>';
      r += c(ctx_1.name, ctx_1);
      r += '</b></p>\n      </div>\n      <div class="block block-strong">\n        ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.sequence, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n        <p class="row">\n          <a href="#" class="col button button-large button-raised button-fill" @click="UpdateEVPlace(';
          r += c(ctx_2, ctx_2);
          r += ')">';
          r += c(ctx_2, ctx_2);
          r += '</a>\n        </p>\n        ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t      <p>No spots for ';
          r += c(ctx_2.Name, ctx_2);
          r += '</p>\n        ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    