
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { DateTime } from 'luxon';
var component = null;
var app;
var $;
var lastTransactionsLimit = 10;
function loadAccountData(acc) {
  var tz = app.data.tz;
  var fetchTotalBalance = app.data.db.collection('transactions').aggregate([
    { '$match': { 'splits.account': acc } },
    { '$unwind': '$splits' },
    {
      '$project': {
        _id: 0,
        account: '$splits.account',
        amount: '$splits.amount',
        type: '$splits.type'
      }
    },
    { '$match': { 'account': acc } },
    {
      '$group': {
        _id: '$account',
        total: {
          '$sum': {
            '$cond': [
              {
                '$in': [
                  '$type',
                  [
                    'asset',
                    'expense'
                  ]
                ]
              },
              '$amount',
              {
                '$multiply': [
                  -1,
                  '$amount'
                ]
              }
            ]
          }
        }
      }
    }
  ]).toArray();
  var fetchLastTransactions = app.data.db.collection('transactions').aggregate([
    { '$match': { 'splits.account': acc } },
    { '$sort': { ts: -1 } },
    { '$limit': lastTransactionsLimit },
    { '$unwind': '$splits' },
    {
      '$project': {
        _id: 1,
        ts: 1,
        description: 1,
        account: '$splits.account',
        amount: {
          '$cond': [
            {
              '$in': [
                '$splits.type',
                [
                  'asset',
                  'expense'
                ]
              ]
            },
            '$splits.amount',
            {
              '$multiply': [
                -1,
                '$splits.amount'
              ]
            }
          ]
        },
        type: '$splits.type'
      }
    },
    { '$match': { 'account': acc } }
  ]).toArray();
  Promise.all([
    fetchTotalBalance,
    fetchLastTransactions
  ]).then(outcome => {
    if (outcome[0][0]) {
      var totalBalance = outcome[0][0]['total'];
      var lastTransactions = outcome[1];
      component.$setState({
        entries: lastTransactions.map(function (el) {
          el.ts = DateTime.fromJSDate(el.ts, { zone: tz }).toLocaleString({
            month: 'short',
            day: '2-digit'
          });
          return el;
        }),
        total_balance: totalBalance
      }, function componentUpdateCallback() {
      });
    } else {
      component.$setState({
        entries: [],
        total_balance: 0
      }, function componentUpdateCallback() {
      });
    }
  }).catch(e => {
    console.error(e);
  });
}
export default {
  data: function defaultData() {
    component = this;
    app = component.$app;
    $ = component.$;
    var accounts;
    return app.data.db.collection('accounts').find({}, {
      sort: {
        type: 1,
        _id: 1
      }
    }).toArray().then(res => {
      accounts = res;
      return {
        total_balance: 0,
        entries: [],
        accounts: res
      };
    }).catch(e => {
      console.error(e);
    });
  },
  on: {
    pageInit: function (e, page) {
      loadAccountData($('#account-selector')[0].value);
    }
  },
  methods: {
    accountSelected: function (el) {
      loadAccountData(el.target.value);
    }
  },
  id: '2bd6080011',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back"></i>\n            <span class="if-not-md">Back</span>\n          </a>\n        </div>\n        <div class="title">Account Info</div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="list">\n        <ul>\n          <li class="item-content item-input">\n            <div class="item-inner">\n              <div class="item-title item-label">Account</div>\n              <div class="item-input-wrap input-dropdown-wrap">\n                <select id="account-selector" @change="accountSelected" placeholder="Choose account...">\n                  ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.accounts, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                  <option value="';
          r += c(ctx_2._id, ctx_2);
          r += '">';
          r += c(ctx_2._id, ctx_2);
          r += ' [';
          r += c(ctx_2.type, ctx_2);
          r += ']</option>\n                  ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </select>\n              </div>\n            </div>\n          </li>\n        </ul>\n      </div>\n      <div id="points-data-table" class="data-table card">\n        <div class="card-header ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.total_balance > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'bg-color-green';
          r += Template7Helpers.js_if.call(ctx_2, 'this.total_balance < 0', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.js_if.call(ctx_2, 'this.total_balance < 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'bg-color-red';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n          <div class="data-table-title">Balance: $';
      r += c(ctx_1.total_balance, ctx_1);
      r += '</div>\n        </div>\n        <div class="card-content ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.total_balance > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'bg-color-green';
          r += Template7Helpers.js_if.call(ctx_2, 'this.total_balance < 0', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.js_if.call(ctx_2, 'this.total_balance < 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'bg-color-red';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n          <table>\n            <thead>\n              <tr>\n                <th class="numeric-cell">Date</th>\n                <th class="numeric-cell">Amount</th>\n                <th class="label-cell">Description</th>\n              </tr>\n            </thead>\n            <tbody>\n              ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.entries, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n              <tr>\n                <td class="numeric-cell ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.amount > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'bg-color-green';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'bg-color-red';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">';
          r += c(ctx_2.ts, ctx_2);
          r += '</td>\n                <td class="numeric-cell ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.amount > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'bg-color-green';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'bg-color-red';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">$';
          r += c(ctx_2.amount, ctx_2);
          r += '</td>\n                <td class="label-cell ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.amount > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'bg-color-green';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'bg-color-red';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.description, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td>\n              </tr>\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </tbody>\n          </table>\n        </div>\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    